import { Hero } from '../../components/Hero';
import { StaticImage } from 'gatsby-plugin-image';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { TestimonialsMainSection } from '../../components/Testimonials-Main-Section';
import { Seo } from '../../components/seo';
const breadCrumbNames = ['About Us', 'Testimonials'];
const breadCrumbUrls = ['/about-us/', '/about-us/testimonials/'];

const Testimonials: Page = () => {
    return (
        <>
            <Seo
                title="Testimonials Lafayette, IN - Innovative Medicine"
                description="We strive to supply our patients with the supplements and treatments they need to stay in good health. We love bring best services to our patients."
            />
            <Hero
                title="Testimonials"
                breadCrumbUrls={breadCrumbUrls}
                breadCrumbNames={breadCrumbNames}
            >
                <StaticImage
                    quality={100}
                    placeholder="none"
                    src="../../images/girl-shaking-hand.jpeg"
                    alt="girl shaking hand "
                />
            </Hero>
            <TestimonialsMainSection />
        </>
    );
};
Testimonials.Layout = DefaultLayout;

export default Testimonials;
