import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors } from './layout/GlobalStyles';
import { SquarePanels } from './Square-Panels';

const links = [
    { name: 'Fibromyalgia-Testimonial', url: '' },
    {
        name: 'My Journey with Pain & Addiction',
        url: '',
    },
    {
        name: 'CBD and Cancer',
        url: '',
    },
    {
        name: 'Heartburn and Mother of Vinegar',
        url: '',
    },
    {
        name: 'Thyroid',
        url: '',
    },
    {
        name: 'Anti-Aging',
        url: '',
    },
    {
        name: 'Pain, It’s gone.',
        url: '',
    },
    {
        name: 'Love Dr. Turner',
        url: '',
    },
    {
        name: 'GERD and Vinegar',
        url: '',
    },
    {
        name: 'I am indebted to Dr. Turner',
        url: '',
    },
    {
        name: 'I was released from my 10 year prison',
        url: '',
    },
    {
        name: 'Testimonal HRT',
        url: '',
    },
    {
        name: 'Bubinorphine',
        url: '',
    },
    {
        name: 'Pain Management',
        url: '',
    },
    {
        name: 'Allergies/Immune Formula',
        url: '',
    },
];

const StyledTestimonialsMainSection = styled.div`
    div {
        margin-top: 32px;
    }
`;

export const TestimonialsMainSection: React.FC = () => {
    return (
        <StyledTestimonialsMainSection className="small-container">
            <p>
                {' '}
                <span style={{ fontWeight: 'bold', color: `${colors.blue2}` }}>
                    Innovative Medicine
                </span>{' '}
                strives to supply our patients with the supplements and treatments they need to stay
                in good health. We love bringing out the best in our patients and seeing them
                achieve levels of wellness that they didn’t think they were capable of.{' '}
            </p>
            <p>
                To help us do our job even better, we’d appreciate some feedback from our satisfied
                patients. Leave a review if you’ve had a positive experience with our practice that
                you’d like us to know about.
            </p>
            <p>
                {' '}
                If you’re curious about one of our treatments and would like to schedule a
                consultation,{' '}
                <a href="tel:7654711100" rel="noopener noreferrer">
                    contact us
                </a>{' '}
                in Lafayette. We hope that we can help you achieve your goals.
            </p>
            <SquarePanels links={links}></SquarePanels>
        </StyledTestimonialsMainSection>
    );
};
