import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';

const StyledSquarePanels = styled.div`
    ul {
        list-style: none;
    }
    li {
        cursor: pointer;
        text-align: center;
        margin: 16px 0px;
        background: #fcfcfc;
        display: flex;
        padding: 32px 15px;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #c0cbcf;
        border-radius: 15px;
        transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    }
    li:hover {
        background: rgba(112, 201, 251, 0.2);
    }
    @media ${device.tablet} {
        ul {
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
        }
        li {
            height: 140px;
            margin-right: 15px;
            width: 250px;
        }
    }
    @media ${device.laptop} {
        li {
            width: 200px;
        }
    }
`;

interface Props {
    links: { name: string; url: string }[];
}

export const SquarePanels: React.FC<Props> = ({ links }) => {
    return (
        <StyledSquarePanels>
            <ul>
                {links.map(link => {
                    return (
                        <li key={link.name}>
                            <Link to={link.url}>
                                <p>{link.name}</p>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </StyledSquarePanels>
    );
};
